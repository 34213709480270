import React from 'react';
import { Box, useMediaQuery, useTheme } from '@mui/material';
import {
  TopTemperatureIcon,
  BottomTemperatureIcon,
  SteamIcon,
} from '../../../theme/icons';
import { SensordataNewestMixed } from '../../../redux/monitoring/api/monitoring.model';
import Sensor from '../DisplayValues/Sensor';
import { useCurrentStateByControlUnitId } from '../../../redux/controlUnit/hooks/useCurrentStateByControlUnitId';

interface DeckSensorsProps {
  on: boolean | undefined;
  topHeat: SensordataNewestMixed | undefined;
  bottomHeat: SensordataNewestMixed | undefined;
  top2Heat: SensordataNewestMixed | undefined;
  steamboiler: SensordataNewestMixed | undefined;
  setpointTopTemperature: SensordataNewestMixed | undefined;
  setpointBottomTemperature: SensordataNewestMixed | undefined;
  setpointSteamTemperature: SensordataNewestMixed | undefined;
  CUTranslate: ReturnType<typeof useCurrentStateByControlUnitId>['CUTranslate']
}
function DeckSensors(props: DeckSensorsProps) {
  const {
    on,
    topHeat,
    bottomHeat,
    // TODO: remove unused variable, or use it to have a middle value
    top2Heat,
    steamboiler,
    setpointTopTemperature,
    setpointBottomTemperature,
    setpointSteamTemperature,
    CUTranslate,
  } = props;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  // TODO: Get translations from the CUs translations, but this example don't have the translations.
  // sp the translations are hardcoded for better explanation
  return (
    <Box
      display={'flex'}
      flexDirection={isMobile ? 'column' : 'row'}
      justifyContent={'space-between'}
    >
      <Sensor
        icon={<TopTemperatureIcon height={'3em'} />}
        sensorName={'Top Temperature'}
        on={on}
        value={topHeat}
        setpoint={setpointTopTemperature}
        CUTranslate={CUTranslate}
      />
      <Sensor
        icon={<BottomTemperatureIcon height={'3em'} />}
        sensorName={'Bottom Temperature'}
        on={on}
        value={bottomHeat}
        setpoint={setpointBottomTemperature}
        CUTranslate={CUTranslate}
      />
      <Sensor
        icon={<SteamIcon height={'3em'} />}
        sensorName={'Steam Temperature'}
        on={on}
        value={steamboiler}
        setpoint={setpointSteamTemperature}
        CUTranslate={CUTranslate}
      />
    </Box>
  );
}

export default DeckSensors;
