import LoadingButton from '@mui/lab/LoadingButton';
import React from 'react';
import CUActionsAPI from '../../../redux/CUActions/api/CUActionsAPI';
import useShowErrorMessage from '../../../handlingErrors/useShowErrorMessage';
import { Box, Stack, Typography } from '@mui/material';
import { useCurrentStateByControlUnitId } from '../../../redux/controlUnit/hooks/useCurrentStateByControlUnitId';
import { getPolyglot } from '../../../i18n';

interface StatusProps {
  value: boolean | undefined;
  icon: React.ReactElement;
  name?: string;
  deviceId: string;
  controlUnitId: string;
  actionId: string;
  CUTranslate: ReturnType<typeof useCurrentStateByControlUnitId>['CUTranslate'];
}
function Status(props: StatusProps) {
  const polyglot = getPolyglot();
  const { value, name, icon, actionId, controlUnitId, deviceId, CUTranslate } =
    props;

  const showErrorMessage = useShowErrorMessage();
  const [triggerAction, { isLoading }] = CUActionsAPI.useTriggerActionMutation({
    fixedCacheKey: `triggerAction-${deviceId}-${controlUnitId}-${actionId}`,
  });

  const internalTranslation = polyglot.t(`device.actions.${actionId}`, {
    _: actionId,
  });
  const translation = (CUTranslate(actionId) as string) || internalTranslation;
  const actionName = translation || name;

  const onClick = () => {
    triggerAction({
      deviceId,
      controlUnitId: parseInt(controlUnitId),
      actionId,
      value: !value,
    })
      .unwrap()
      .catch((error) => {
        error.message.startsWith('POLLING_')
          ? showErrorMessage('device.controlUnit.action.error.pooling', {
              variant: 'warning',
            })
          : showErrorMessage(error);
      });
  };

  return (
    <Stack spacing={0.5} direction={'column'} alignItems={'center'}>
      <LoadingButton
        variant="outlined"
        onClick={onClick}
        sx={{
          backgroundColor: value ? '#0169b410' : '',
          flexDirection: 'column',
        }}
        loading={isLoading}
      >
        {!isLoading && (
          <>
            <Box
              sx={{
                fontSize: '1.5em',
                display: 'flex',
                color: value ? '#0169b4' : '',
                justifyContent: 'center',
              }}
              padding={0.2}
            >
              {icon}
            </Box>
            <Typography
              color={value ? '#0169b4' : ''}
              sx={
                value
                  ? {
                      textDecoration: 'underline',
                      textUnderlineOffset: '0.3em',
                    }
                  : {}
              }
            >
              {actionName}
            </Typography>
          </>
        )}
      </LoadingButton>
    </Stack>
  );
}

export default Status;
