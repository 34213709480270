import React from 'react';
import useShowErrorMessage from '../../../handlingErrors/useShowErrorMessage';
import { ControlUnitAPIResponse } from '../../../redux/controlUnit/api/ControlUnit.model';
import { useCurrentStateByDeckOvenId } from '../../../redux/composableDevice/hooks/useCurrentStateByControlUnitId';
import {
  Box,
  Divider,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import DeckActionsStatus from './DeckActionsStatus';
import DeckSensors from './DeckSensors';
import { useNavigate } from 'react-router-dom';

export interface DeckControlUnitOvenProps {
  controlUnit: ControlUnitAPIResponse;
  encodedDeviceId: string;
  deviceName?: string;
  deviceId: string;
}
function DeckControlUnitOven(props: DeckControlUnitOvenProps) {
  const { controlUnit, encodedDeviceId, deviceName, deviceId } = props;
  const showErrorMessage = useShowErrorMessage();

  const {
    deckOvenData: {
      analog: {
        topHeat,
        bottomHeat,
        top2Heat,
        steamboiler,
        setpointTopTemperature,
        setpointBottomTemperature,
        setpointSteamTemperature,
      },
      actions: { on, light, clapet, burner, multiFunction, fan },
    },
    currentStateControlUnit: { CUTranslate },
  } = useCurrentStateByDeckOvenId(
    encodedDeviceId,
    controlUnit?.id,
    showErrorMessage
  );

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        backgroundColor: theme.palette.background.default,
        padding: '1rem',
      }}
    >
      <Box
        display={'flex'}
        flexDirection={isMobile ? 'column' : 'row'}
        justifyContent={'space-between'}
      >
        <Box
          display={'flex'}
          flexDirection={isMobile ? 'row' : 'column'}
          alignItems={'center'}
          justifyContent={isMobile ? 'space-between' : 'space-evenly'}
          sx={{ backgroundColor: theme.palette.background.paper }}
          width={isMobile ? '100%' : 'min-content'}
          padding={1}
        >
          <Box padding={1}>
            <Typography variant="h3">{deviceName}</Typography>
          </Box>
          <DeckActionsStatus
            on={on}
            deviceId={deviceId}
            controlUnitId={controlUnit.id}
            CUTranslate={CUTranslate}
          />
        </Box>
        <Box
          width={isMobile ? '100%' : '88%'}
          sx={{
            backgroundColor: on?.value
              ? theme.palette.background.paper
              : '#F4F4F4',
          }}
          padding={1}
        >
          <Box
            display={'flex'}
            flexDirection={'column'}
            justifyContent={'space-between'}
          >
            <div
              onClick={() =>
                navigate(
                  `/devicemanager/device/${encodedDeviceId}/device-detail/`
                )
              }
            >
              <DeckSensors
                on={on?.value}
                topHeat={topHeat}
                top2Heat={top2Heat}
                bottomHeat={bottomHeat}
                steamboiler={steamboiler}
                setpointTopTemperature={setpointTopTemperature}
                setpointBottomTemperature={setpointBottomTemperature}
                setpointSteamTemperature={setpointSteamTemperature}
                CUTranslate={CUTranslate}
              />
              <Divider sx={{ margin: 1 }} />{' '}
            </div>
            <Box
              sx={{
                padding: 1,
              }}
            >
              <DeckActionsStatus
                deviceId={deviceId}
                controlUnitId={controlUnit.id}
                light={light}
                clapet={clapet}
                burner={burner}
                multiFunction={multiFunction}
                fan={fan}
                CUTranslate={CUTranslate}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
export default DeckControlUnitOven;
