import React from 'react';
import { Stack, useMediaQuery, useTheme } from '@mui/material';

import {
  PowerIcon,
  TurboIcon,
  LightIcon,
  FanIcon,
  ClapetIcon,
} from '../../../theme/icons';
import { ActionExtendedValue } from '../../../redux/controlUnit/selectors/actions';
import Status from '../DisplayValues/StatusOven';
import { useCurrentStateByControlUnitId } from '../../../redux/controlUnit/hooks/useCurrentStateByControlUnitId';

interface DeckActionsStatusProps {
  deviceId: string;
  controlUnitId: string;
  on?: ActionExtendedValue;
  light?: ActionExtendedValue;
  clapet?: ActionExtendedValue;
  burner?: ActionExtendedValue;
  multiFunction?: ActionExtendedValue;
  fan?: ActionExtendedValue;
  CUTranslate: ReturnType<typeof useCurrentStateByControlUnitId>['CUTranslate'];
}

function DeckActionsStatus({
  deviceId,
  controlUnitId,
  on,
  light,
  clapet,
  burner,
  multiFunction,
  fan,
  CUTranslate,
}: DeckActionsStatusProps) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Stack
      justifyContent={isMobile ? 'center' : 'flex-end'}
      flexWrap={'wrap'}
      direction={'row'}
      justifyItems={'center'}
      alignItems={'center'}
      gap={2}
    >
      {on && (
        <Status
          value={on?.value}
          icon={<PowerIcon height={'1em'} />}
          name={on?.value ? 'On' : 'Off'}
          deviceId={deviceId}
          controlUnitId={controlUnitId}
          actionId={on?.metadata.id}
          CUTranslate={CUTranslate}
        />
      )}
      {light && (
        <Status
          value={light?.value}
          icon={<LightIcon height={'1em'} />}
          name={'Light'}
          deviceId={deviceId}
          controlUnitId={controlUnitId}
          actionId={light?.metadata.id}
          CUTranslate={CUTranslate}
        />
      )}
      {fan && (
        <Status
          value={fan?.value}
          icon={<FanIcon height={'1em'} />}
          name={'Fan'}
          deviceId={deviceId}
          controlUnitId={controlUnitId}
          actionId={fan?.metadata.id}
          CUTranslate={CUTranslate}
        />
      )}
      {clapet && (
        <Status
          value={clapet?.value}
          icon={<ClapetIcon height={'1em'} />}
          name={'Clapet'}
          deviceId={deviceId}
          controlUnitId={controlUnitId}
          actionId={clapet?.metadata.id}
          CUTranslate={CUTranslate}
        />
      )}
      {/* {burner && (
        <Status
          value={burner?.value}
          icon={<BurnerIcon height={'1em'} />}
          name={'Burner'}
          deviceId={deviceId}
          controlUnitId={controlUnitId}
          actionId={burner?.metadata.id}
        />
      )} */}
      {multiFunction && (
        <Status
          value={multiFunction?.value}
          icon={<TurboIcon height={'1em'} />}
          name={'Turbo'}
          deviceId={deviceId}
          controlUnitId={controlUnitId}
          actionId={multiFunction?.metadata.id}
          CUTranslate={CUTranslate}
        />
      )}
    </Stack>
  );
}

export default DeckActionsStatus;
