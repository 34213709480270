import { Box, Typography } from '@mui/material';
import React from 'react';
import { SensordataNewestMixed } from '../../../redux/monitoring/api/monitoring.model';
import { getPolyglot } from '../../../i18n';
import { useCurrentStateByControlUnitId } from '../../../redux/controlUnit/hooks/useCurrentStateByControlUnitId';

const DISABLED_TEXT = '-- / --';
interface SensorProps {
  icon: React.ReactElement;
  sensorName: string;
  on: boolean | undefined;
  value: SensordataNewestMixed | undefined;
  setpoint?: SensordataNewestMixed | undefined | null;
  CUTranslate: ReturnType<typeof useCurrentStateByControlUnitId>['CUTranslate'];
}
function Sensor(props: SensorProps) {
  const { icon, sensorName, on, value, setpoint, CUTranslate } = props;
  const polyglot = getPolyglot();
  const valueText = `${
    value?.value.toFixed(value.metadata?.decimals ?? 2) ?? '--'
  }${value?.metadata?.unitString ?? ''}`;
  const setpointText =
    setpoint === null
      ? ''
      : `/ ${
          setpoint?.value.toFixed(setpoint.metadata?.decimals ?? 2) ?? '--'
        }${setpoint?.metadata?.unitString ?? ''}`;

  const name =
    (value?.metadata?.id && (CUTranslate(value?.metadata?.id) as string)) ||
    value?.metadata?.name;
  return (
    <Box
      display={'flex'}
      flexDirection={'row'}
      alignItems={'center'}
      padding={1}
    >
      <Box padding={0.2}>{icon}</Box>
      <Box display={'flex'} flexDirection={'column'} ml={1}>
        <Typography variant="body1" fontSize="0.8em">
          {name}
        </Typography>
        <Typography color="#2A2A2B" fontSize="1.5em">
          {on ? `${valueText} ${setpointText}` : DISABLED_TEXT}
        </Typography>
      </Box>
    </Box>
  );
}

export default Sensor;
